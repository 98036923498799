import React, { Component } from "react";
import { observer } from "mobx-react";
import autobind from "autobind-decorator";
import {
  Row,
  Col,
  Timeline,
  Icon,
  Button as BT,
  Modal,
  Spin,
  Input,
  Form,
  message,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
} from "antd";
import moment from "moment";
import { BtnGoback } from "../../../components/Button";
import { image } from "../../../resource";
import { removeDuplicates } from "../../../functions/functions";
import {
  ip,
  GET,
  POST,
  RIZZ_DETAILCLAIMBYID,
  RIZZ_GETDETIALBYBARCODE,
  RIZZ_UPDATE_CLAIM,
  RIZZ_GET_OPTION_BREAK,
  RIZZ_GET_DETIAL_BY_CLAIMNO,
  RIZZ_GET_ADDRESS_BY_POSTCODE,
  RIZZ_GET_OPTION_BANK,
  RIZZ_UPDATE_CLAIM_PAYMENT,
} from "../../../service";
import Lightbox from "react-image-lightbox";
import {
  EditFilled,
  SearchOutlined,
  CloseOutlined,
  WarningOutlined,
  EnterOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import socketIOClient from "socket.io-client";

const { Option } = Select;
const fn = require("../../../functions/functions");
const styles = {
  font: {
    fontSize: ".8vw",
    marginTop: 3,
  },
  border: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  border_part1: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    marginTop: 15,
  },
};
@Form.create({ name: "EditClaimScene" })
@observer
class EditClaimScene extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      claimNo: query.get("id"),
      loading: true,
      visible: false,
      address: [],
      product: [],
      dataAddress: [],
      customerName: "",
      customerTelephone: "",
      detail: "",
      email: "",
      address: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: "",
      claimImage: "",
      claimImageFile: "",
      claimImageUrl: "",
      paymentImageUrl: "",
      paymentImageFile: "",
      barcode: "",
      searchedBarcode: "",
      claimRemark: "",
      breakdownID: "",
      claimDetail: "",
      productName: "",
      productTypeID: "",
      isEditProduct: false,
      searchedBarcode: "",
      prepBarcode: "8859",
      isConfirmEdit: false,
      arr_breakdown: [],
      modalMessage: {
        regMsgTitle: "ยืนยันการแก้ไข",
        regMsg: "ท่านต้องการแก้ไขสินค้านี้ใช่หรือไม่",
        status: <WarningOutlined />,
        color: "#ffc107",
      },
      dataPay: {},
      isConfirmShow: false,
      amphoe_option: [],
      district_option: [],
      optionBank: [],
    };
  }

  async componentDidMount() {
    await this.getDetailByClaimNo(this.state.claimNo);
    await this.getStateForEdit();
    await this.getOptionBank();
    this.setState({ userLoginID: localStorage.getItem("userLoginId") });
    if (
      this.state.detail !== "" &&
      this.state.detail !== null &&
      this.state.detail !== undefined
    ) {
      this.getOptionBreak();
    }
  }

  @autobind
  async getOptionBank() {
    try {
      const r = await GET(RIZZ_GET_OPTION_BANK);
      if (r.success) {
        const option = r.result.map((el) => {
          return { value: el.bankName, label: el.bankName };
        });
        console.log({ option });
        this.setState({ optionBank: option });
      }
    } catch (err) {}
  }

  @autobind
  async getDetailByClaimNo(claimNo) {
    try {
      const r = await GET(RIZZ_GET_DETIAL_BY_CLAIMNO + claimNo);
      if (r.success) {
        this.setState({ detail: r.result, loading: false }, () =>
          this.getAddressByZipCode(r.result.zipcode, "default")
        );
      }
    } catch (err) {}
  }

  @autobind
  getStateForEdit() {
    const { detail } = this.state;
    this.setState(
      {
        email: detail.email,
        address: detail.address,
        subdistrict: detail.subdistrict,
        district: detail.district,
        barcode: detail.barcode,
        searchedBarcode: detail.barcode,
        province: detail.province,
        zipcode: detail.zipcode,
        claimImage: detail.claimImage,
        bill_pay: detail.bill_pay,
        // paymentImageFile: detail.bill_pay,
        paymentImageUrl:detail.bill_pay,
        claimRemark: detail.claimRemark,
        claimDetail: detail.claimDetail,
        productName: detail.productName,
        productImage: detail.productImage,
        productTypeID: detail.productTypeID,
        breakdownID: detail.breakdownID,
        customerName: detail.customerName,
        customerTelephone: detail.telephone,
        dataPay: {
          bank_pay: detail.bank_pay || "ไทยพาณิชย์",
          money_pay: detail.money_pay || 40,
          bill_pay: detail.bill_pay || "",
          date_pay: detail.date_pay || moment(new Date()).format("DD/MM/YYYY"),
          time_pay: detail.time_pay || moment(new Date()).format("HH:mm"),
        },
      },
      () => {
        const bc = this.state.barcode.substr(this.state.barcode.length - 9);
        this.setState({ barcode: bc }, () =>
          console.log("barcode", this.state.barcode)
        );
      }
    );
  }

  @autobind
  async getOptionBreak() {
    try {
      const r = await GET(RIZZ_GET_OPTION_BREAK + this.state.productTypeID);
      if (r.success) {
        this.setState({ arr_breakdown: r.result });
      } else {
        message.error("เกิดข้อผิดพลาด");
      }
    } catch (err) {
      message.error(err.message);
    }
  }

  getAddressByZipCode = async (zipcode, ref) => {
    try {
      const res = await GET(RIZZ_GET_ADDRESS_BY_POSTCODE(zipcode));
      const { success, result } = res;
      if (success) {
        let amphoe = [];
        let district = [];
        let province = "";
        result.forEach((el) => {
          amphoe.push({ label: el.amphoe, value: el.amphoe });
          if (amphoe[0].value === el.amphoe) {
            district.push({ label: el.district, value: el.district });
          }
          province = el.province;
        });

        if (ref !== "default") {
          this.setState({
            province,
          });

          this.props.form.setFieldsValue({
            province,
          });
        }

        this.setState({
          amphoe_option: removeDuplicates(amphoe),
          district_option: removeDuplicates(district),
          dataAddress: result,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    }
  };

  @autobind
  async onFetchClaimById() {
    const { claim_doc } = this.state;
    try {
      let res = await POST(RIZZ_DETAILCLAIMBYID, { claim_doc });
      if (res.success) {
        const data = res.result.detailclaim;

        this.setState({
          loading: false,
          status: res.result.detailclaim.status,
          ...data,
          ...data.address,
          ...data.product,
          subdistrict: data.address.amphure,
          district: data.address.district,
          addressId: data.address.address_id,
          register_url: res.result.detailclaim.register_url,
        });
      }
    } catch (error) {
      // console.log(error)
    }
  }
  @autobind
  showModal() {
    this.setState({
      visible: true,
    });
  }
  @autobind
  async onChange(e, ref) {
    const { dataAddress, dataPay } = this.state;
    console.log({ e });
    await this.setState({ [ref]: e });
    switch (ref) {
      case "claimDetail":
        if (
          this.state.productTypeID === 99 ||
          this.state.productTypeID === "99"
        ) {
          let val = e.trimLeft();
          this.setState({ breakdownID: "", claimDetail: val });
        } else {
          let id = e.split("|")[0];
          let datail = e.split("|")[1];
          this.setState({ breakdownID: id, claimDetail: datail });
        }
        break;
      case "zipcode":
        if (e.length === 5) {
          this.getAddressByZipCode(e, "onchange");
        }
        break;
      case "district":
        const district = [];
        dataAddress.forEach((el) => {
          if (el.amphoe === e) {
            district.push({ label: el.district, value: el.district });
          }
        });
        this.props.form.setFieldsValue({
          subdistrict: "",
        });
        this.setState({ district_option: district, subdistrict: "" });
        break;
      case "money_pay":
        dataPay.money_pay = e;
        this.setState({ dataPay });
        break;
      case "date_pay":
        dataPay.date_pay = e;
        this.setState({ dataPay });
        break;
      case "time_pay":
        dataPay.time_pay = e;
        this.setState({ dataPay });
        break;
    }
  }

  _handleImageChange(e, name) {
    if (e.target.files.length > 0) {
      const imgFile = e.target.files[0];
      const imgUrl = URL.createObjectURL(imgFile);
      if (name === "CLAIM") {
        this.setState({ claimImageUrl: imgUrl, claimImageFile: imgFile });
      }

      if (name === "PAYMENT") {
        this.setState({ paymentImageUrl: imgUrl, paymentImageFile: imgFile });
      }
    }
  }

  @autobind
  checkValidate() {
    const { subdistrict, district, province, zipcode, address, claimDetail } =
      this.state;
    if (
      subdistrict === "" ||
      subdistrict === null ||
      subdistrict === undefined
    ) {
      message.error("กรุณากรอกแขวง/ตำบล");
    } else if (district === "" || district === null || district === undefined) {
      message.error("กรุณากรอกเขต/อำเภอ");
    } else if (province === "" || province === null || province === undefined) {
      message.error("กรุณากรอกจังหวัด");
    } else if (zipcode === "" || zipcode === null || zipcode === undefined) {
      message.error("กรุณากรอกไปรษณีย์");
    } else if (address === "" || address === null || address === undefined) {
      message.error("กรุณากรอกที่อยู่(เพิ่มเติม)");
    } else if (
      claimDetail === "" ||
      claimDetail === null ||
      claimDetail === undefined
    ) {
      message.error("กรุณากรอกอาการเสีย");
    } else {
      this.setState({ isConfirmShow: true });
    }
  }

  successReturn() {
    this.setState({
      modalMessage: {
        ...this.state.modalMessage,
        open: false,
        regMsgTitle: "ลงทะเบียนสำเร็จ",
        regMsg: "แก้ไขสำเร็จ",
        status: <CheckCircleOutlined />,
        color: "#28a745",
      },
      isRegSuccess: true,
    });
    this.getDetailByClaimNo(this.state.claimNo);
    this.sendToSocket();
  }
  @autobind
  async updateClaim() {
    try {
      this.setState({
        modalMessage: {
          ...this.state.modalMessage,
          open: false,
          regMsgTitle: "ยืนยันการแก้ไข",
          regMsg: <LoadingOutlined spin />,
          status: <WarningOutlined />,
        },
        isRegSuccess: false,
      });
      const {
        claimNo,
        searchedBarcode,
        breakdownID,
        claimDetail,
        claimRemark,
        claimImageFile,
        address,
        province,
        district,
        subdistrict,
        zipcode,
        email,
        userLoginID,
        customerName,
        customerTelephone,
        paymentImageFile,
        paymentImageUrl,
        detail,
        dataPay,
      } = this.state;
      let dataUpdate = new FormData();
      let dataUpdatePayment = new FormData();

      dataUpdate.append(["claimNo"], claimNo);
      dataUpdate.append(["barcode"], searchedBarcode);
      dataUpdate.append(["breakdownID"], breakdownID);
      dataUpdate.append(["claimDetail"], claimDetail);
      dataUpdate.append(["claimRemark"], claimRemark || "");
      dataUpdate.append(["claimImage"], claimImageFile || "");
      dataUpdate.append(["address"], address);
      dataUpdate.append(["province"], province);
      dataUpdate.append(["district"], district);
      dataUpdate.append(["subdistrict"], subdistrict);
      dataUpdate.append(["zipcode"], zipcode);
      dataUpdate.append(["email"], email || "");
      dataUpdate.append(["userLogin"], userLoginID || "");
      dataUpdate.append(["customerName"], customerName);
      dataUpdate.append(["customerTelephone"], customerTelephone);

    

      dataUpdatePayment.append(["claimNo"], claimNo);
      dataUpdatePayment.append(["warrantyNo"], detail.warrantyNo);
      dataUpdatePayment.append(["userLogin"], userLoginID || '');
      dataUpdatePayment.append(["customerTelephone"], customerTelephone);
      dataUpdatePayment.append(["paymentImage"], paymentImageFile || "");
      dataUpdatePayment.append(["bill_pay"], !paymentImageFile && dataPay.bill_pay?dataPay.bill_pay:'');
      dataUpdatePayment.append(["bank_pay"], dataPay.bank_pay || "");
      dataUpdatePayment.append(["date_pay"], moment(dataPay.date_pay,'DD/MM/YYYY').format('YYYY-MM-DD')|| "");
      dataUpdatePayment.append(["time_pay"], moment(dataPay.time_pay,'HH:mm').format('HH:mm')|| "");
      dataUpdatePayment.append(["money_pay"], dataPay.money_pay || "");

      const r = await POST(RIZZ_UPDATE_CLAIM, dataUpdate);
      if (r.success) {
        if (paymentImageUrl) {
          const r_payment = await POST(
            RIZZ_UPDATE_CLAIM_PAYMENT,
            dataUpdatePayment
          );
          if (r_payment.success) {
            this.successReturn();
          }
        } else {
          this.successReturn();
        }
      } else {
        this.setState({
          modalMessage: {
            ...this.state.modalMessage,
            open: false,
            regMsgTitle: "ลงทะเบียนไม่สำเร็จ",
            regMsg: "แก้ไขไม่สำเร็จ",
            status: <StopOutlined />,
            color: "#dc3545",
          },
          isRegSuccess: false,
        });
      }
    } catch (err) {
      this.setState({
        modalMessage: {
          ...this.state.modalMessage,
          open: false,
          regMsgTitle: "ลงทะเบียนไม่สำเร็จ",
          regMsg: "แก้ไขไม่สำเร็จ (" + err.message + ")",
          status: <StopOutlined />,
          color: "#dc3545",
        },
        isRegSuccess: false,
      });
    }
  }
  @autobind
  sendToSocket() {
    const socket = socketIOClient("https://dplus-service.com:1141");
    socket.emit("afterApprove", "success");
  }

  @autobind
  async closeConfirm() {
    await this.setState({ isConfirmShow: false, isRegSuccess: false });
    this.setState({
      modalMessage: {
        ...this.state.modalMessage,
        open: false,
        regMsgTitle: "ยืนยันการแก้ไข",
        regMsg: "ท่านต้องการแก้ไขสินค้านี้ใช่หรือไม่",
        status: <WarningOutlined />,
        color: "#ffc107",
      },
    });
  }

  @autobind
  goBack() {
    this.props.history.goBack();
  }
  onSelect = (fullAddress) => {
    const { subdistrict, district, province, zipcode } = fullAddress;
    this.setState({
      subdistrict,
      district,
      province,
      zipcode,
    });
  };
  @autobind
  onChangeAddress(e, ref) {
    this.setState({ [ref]: e.target.value });
  }
  @autobind
  clickImage(src_image) {
    this.setState({
      zoomImage: src_image,
      isOpen: true,
    });
  }
  @autobind
  editProduct(barcode) {
    this.setState({ isEditProduct: true });
  }
  @autobind
  async searchBarcode(barcode) {
    try {
      let fullbarcode = this.state.prepBarcode + barcode;
      const r = await GET(RIZZ_GETDETIALBYBARCODE + fullbarcode);
      if (r.success) {
        this.setState({
          searchedBarcodeTemp: fullbarcode,
          productNameTemp: r.result.productName,
          productImageTemp: r.result.productImage,
          productTypeIDTemp: r.result.productTypeID,
          isConfirmEdit: true,
        });
      } else {
        message.error("เกิดข้อผิดพลาด");
      }
    } catch (err) {
      message.error(`เกิดข้อผิดพลาด (${err.message})`);
    }
  }
  @autobind
  confirmEditProduct() {
    this.setState(
      {
        searchedBarcode: this.state.searchedBarcodeTemp,
        productName: this.state.productNameTemp,
        productImage: this.state.productImageTemp,
        isEditProduct: false,
        isConfirmEdit: false,
        productTypeID: this.state.productTypeIDTemp,
      },
      () => {
        this.setState({ breakdownID: "", claimDetail: "" });
        this.getOptionBreak();
      }
    );
  }

  @autobind
  closeConfirmEdit() {
    this.setState({ isConfirmEdit: false });
  }

  render() {
    const {
      isOpen,
      claim_id,
      visible,
      loading,
      customerTelephone,
      claimdate,
      amphoe_option,
      district_option,
      detail,
      email,
      subdistrict,
      district,
      province,
      zipcode,
      claimImage,
      claimImageUrl,
      claimRemark,
      address,
      barcode,
      productName,
      productImage,
      customerName,
      paymentImageUrl,
      dataPay,
      optionBank,
    } = this.state;

    console.log({ dataPay });

    return (
      <React.Fragment>
        {this.state.loading ? (
          <div>
            <Icon
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontSize: "6vw",
              }}
              type="loading"
            />
          </div>
        ) : (
          <Form onSubmit={this.handleSubmit}>
            <div>
              <BtnGoback
                title={"กลับ"}
                onClick={() => this.goBack()}
              ></BtnGoback>
              <Modal
                title="ยืนยันการรับงาน"
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={"30vw"}
                cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
                okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
              >
                <p>กรุณากดยืนยันเพื่อรับงาน {claim_id}</p>
              </Modal>
              {isOpen && (
                <Lightbox
                  mainSrc={this.state.zoomImage}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                />
              )}
              {/* <Edit address={address.zipcode}></Edit> */}
              {/* #################################   สถานะ  #################################*/}
              <Row>
                <Col span={6} push={18}>
                  <div
                    style={{
                      border: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#FFFFFF",
                      margin: 15,
                    }}
                  >
                    <div
                      className={"column"}
                      style={{
                        margin: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h3 style={{ fontSize: "1vw" }}>สถานะ</h3>
                    </div>
                    <div
                      className={"column"}
                      style={{
                        margin: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Timeline>
                        <Timeline.Item
                          dot={
                            <Icon
                              type="check-circle"
                              style={{
                                fontSize:
                                  detail.claimStatus === 5 ? "1.5vw" : "1.1vw",
                              }}
                            />
                          }
                          color={detail.claimStatus === 5 ? "red" : "black"}
                          style={{ marginTop: 20 }}
                        >
                          <font
                            className={"text-status"}
                            style={{ fontSize: ".8vw" }}
                          >
                            รอชำระเงิน
                          </font>
                        </Timeline.Item>
                        <Timeline.Item
                          dot={
                            <Icon
                              type="check-circle"
                              style={{
                                fontSize:
                                  detail.claimStatus === 2 ? "1.5vw" : "1.1vw",
                              }}
                            />
                          }
                          color={detail.claimStatus === 2 ? "red" : "black"}
                          style={{ marginTop: 20 }}
                        >
                          <font
                            className={"text-status"}
                            style={{ fontSize: ".8vw" }}
                          >
                            รอตรวจสอบ
                          </font>
                        </Timeline.Item>
                        <Timeline.Item
                          dot={
                            <Icon
                              type="check-circle"
                              style={{
                                fontSize:
                                  detail.claimStatus === 6 ? "1.5vw" : "1.1vw",
                              }}
                            />
                          }
                          color={detail.claimStatus === 6 ? "red" : "black"}
                          style={{ marginTop: 20 }}
                        >
                          <font
                            className={"text-status"}
                            style={{ fontSize: ".8vw" }}
                          >
                            ชำระเงินเรียบร้อย
                          </font>
                        </Timeline.Item>
                        <Timeline.Item
                          dot={
                            <Icon
                              type="check-circle"
                              style={{
                                fontSize:
                                  detail.claimStatus === 3 ? "1.5vw" : "1.1vw",
                              }}
                            />
                          }
                          color={detail.claimStatus === 3 ? "red" : "black"}
                          style={{ marginTop: 15 }}
                        >
                          <font
                            className={"text-status"}
                            style={{ fontSize: ".8vw" }}
                          >
                            ดำเนินการ
                          </font>
                        </Timeline.Item>
                        <Timeline.Item
                          dot={
                            <Icon
                              type="check-circle"
                              style={{
                                fontSize:
                                  detail.claimStatus === 1 ? "1.5vw" : "1.1vw",
                              }}
                            />
                          }
                          color={detail.claimStatus === 1 ? "green" : "black"}
                          style={{ marginTop: 15 }}
                        >
                          <font
                            className={"text-status"}
                            style={{ fontSize: ".8vw" }}
                          >
                            จัดส่งเรียบร้อย
                          </font>
                        </Timeline.Item>
                        <Timeline.Item
                          dot={
                            <Icon
                              type="check-circle"
                              style={{
                                fontSize:
                                  detail.claimStatus === 4 ? "1.5vw" : "1.1vw",
                              }}
                            />
                          }
                          color={detail.claimStatus === 4 ? "red" : "black"}
                          style={{ marginTop: 15 }}
                        >
                          <font
                            className={"text-status"}
                            style={{ fontSize: ".8vw" }}
                          >
                            ยกเลิกเคลม
                          </font>
                        </Timeline.Item>
                      </Timeline>
                    </div>
                  </div>
                </Col>
                {/* #################################   ข้อมูลส่วนตัว  #################################*/}
                <Col span={18} pull={6}>
                  <div
                    style={{
                      border: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#CCCCCC",
                      margin: 15,
                    }}
                  >
                    <div className={"column"} style={{ margin: 10 }}>
                      <h3 style={{ fontSize: "1vw" }}>ข้อมูลส่วนตัว</h3>
                    </div>

                    <div
                      className={"column"}
                      style={{ margin: 15, marginTop: -15, marginLeft: 25 }}
                    >
                      <div className={"row-between"}>
                        <font style={styles.font}>{detail.lineName}</font>

                        <font style={styles.font}>{detail.lineName}</font>
                      </div>
                      <div
                        className={"row-between"}
                        style={{ fontSize: "1cm" }}
                      >
                        <font style={styles.font}>ชื่อ-นามสกุล</font>
                        <Input
                          value={customerName}
                          style={{ width: "30%" }}
                          onChange={(e) =>
                            this.onChange(e.target.value, "customerName")
                          }
                        />
                      </div>
                      <div className={"row-between input-number"}>
                        <font style={styles.font}>หมายเลขโทรศัพท์</font>
                        <Input
                          value={customerTelephone}
                          style={{ width: "30%" }}
                          onChange={(e) =>
                            this.onChange(e.target.value, "customerTelephone")
                          }
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 10);
                          }}
                          type="number"
                        />
                      </div>
                      <div className={"row-between"}>
                        <div>
                          <font style={styles.font}>ที่อยู่</font>
                        </div>
                        <div className={"column"}>
                          <div style={{ display: "flex", marginBottom: "1em" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Input
                                style={{ marginRight: 10, width: "95%" }}
                                value={zipcode}
                                placeholder={"รหัสไปรษณีย์"}
                                onChange={(e) =>
                                  this.onChange(e.target.value, "zipcode")
                                }
                              />
                              <Input
                                style={{ marginRight: 10, width: "95%" }}
                                value={province}
                                placeholder={"จังหวัด"}
                                disabled
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Select
                                style={{ width: "190px", marginRight: "10px" }}
                                placeholder="เขต/อำเภอ"
                                value={district}
                                onChange={(e) => this.onChange(e, "district")}
                              >
                                {amphoe_option.map((item, i) => (
                                  <Select.Option value={item.value} key={i}>
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                              <Select
                                style={{ width: "190px", marginRight: "10px" }}
                                placeholder="แขวง/ตำบล"
                                value={subdistrict}
                                onChange={(e) =>
                                  this.onChange(e, "subdistrict")
                                }
                              >
                                {district_option.map((item, i) => (
                                  <Select.Option value={item.value} key={i}>
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"row-between"}>
                        <font style={styles.font}>ที่อยู่(เพิ่มเติม)</font>
                        {/* <font style={styles.font}>{other}</font> */}
                        <Input.TextArea
                          style={{
                            width: "100%",
                            alignItems: "right",
                            marginRight: "10px",
                          }}
                          value={address}
                          onChange={(e) =>
                            this.onChange(e.target.value, "address")
                          }
                          autosize={{ minRows: 2, maxRows: 4 }}
                        />
                      </div>
                      <div className={"row-between"}>
                        <font style={styles.font}>อีเมล(ถ้ามี)</font>
                        {/* <font style={styles.font}>{other}</font> */}
                        <Input.TextArea
                          style={{
                            width: "30%",
                            alignItems: "right",
                            fontSize: ".8vw",
                          }}
                          value={email}
                          onChange={(e) =>
                            this.onChange(e.target.value, "email")
                          }
                          autosize={{ minRows: 1, maxRows: 2 }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* #################################   button  #################################*/}
              <Row>
                <Col span={6} push={18}></Col>
                {/* #################################   ข้อมูลการแจ้งเคลม  #################################*/}
                <Col span={6} pull={6}>
                  <div
                    style={{
                      border: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#CCCCCC",
                      margin: 15,
                      marginTop: -3,
                      height: "10cm",
                    }}
                  >
                    <div className={"column"} style={{ margin: 10 }}>
                      <h3 style={{ fontSize: "1vw" }}>ข้อมูลการลงทะเบียน</h3>
                    </div>
                    <div
                      className={"column"}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 35,
                      }}
                    >
                      {productImage !== "" &&
                      productImage !== null &&
                      productImage !== undefined ? (
                        <img
                          src={
                            productImage !== "" &&
                            productImage !== null &&
                            productImage !== undefined
                              ? ip + "/" + productImage
                              : image.rizz_logo
                          }
                          onClick={() =>
                            this.clickImage(
                              productImage
                                ? ip + "/" + detail.productImage
                                : image.rizz_logo
                            )
                          }
                          alt="new"
                          style={{
                            width: "6cm",
                            height: "7cm",
                            marginBottom: 50,
                          }}
                        />
                      ) : (
                        <img
                          src={
                            detail.warrantyImage !== "" &&
                            detail.warrantyImage !== null &&
                            detail.warrantyImage !== undefined &&
                            (productName == "" || productName == null)
                              ? ip + "/image?path=" + detail.warrantyImage
                              : image.rizz_logo
                          }
                          onClick={() =>
                            this.clickImage(
                              detail.warrantyImage
                                ? ip + "/image?path=" + detail.warrantyImage
                                : image.rizz_logo
                            )
                          }
                          alt="new"
                          style={{
                            width: "6cm",
                            height: "7cm",
                            marginBottom: 50,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                {/* #################################   รายละเอียดการเคลม  #################################*/}
                <Col span={6} pull={6}>
                  <div
                    style={{
                      border: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#CCCCCC",
                      margin: 15,
                      marginTop: -3,
                      height: "10cm",
                    }}
                  >
                    <div className={"column"} style={{ margin: 10 }}>
                      <h3 style={{ fontSize: "1vw" }}>ข้อมูลการแจ้งเคลม</h3>
                    </div>
                    <div
                      className={"column"}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 35,
                      }}
                    >
                      {claimImageUrl ? (
                        <img
                          src={claimImageUrl || image.rizz_logo}
                          onClick={() =>
                            this.clickImage(claimImageUrl || image.rizz_logo)
                          }
                          alt="new"
                          style={{
                            width: "6cm",
                            height: "7cm",
                            marginBottom: 10,
                          }}
                        />
                      ) : (
                        <img
                          src={
                            claimImage
                              ? ip + "/image?path=" + claimImage
                              : image.rizz_logo
                          }
                          onClick={() =>
                            this.clickImage(
                              claimImage
                                ? ip + "/image?path=" + claimImage
                                : image.rizz_logo
                            )
                          }
                          alt="new"
                          style={{
                            width: "6cm",
                            height: "7cm",
                            marginBottom: 10,
                          }}
                        />
                      )}
                      <input
                        className="fileInput"
                        style={{ marginLeft: 30 }}
                        type="file"
                        onChange={(e) => this._handleImageChange(e, "CLAIM")}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={6} pull={6}>
                  <div
                    style={{
                      border: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#CCCCCC",
                      margin: 15,
                      marginTop: -3,
                      padding: "0px 30px",
                    }}
                  >
                    <div className={"column"} style={{ margin: 10 }}>
                      <h3 style={{ fontSize: "1vw" }}>ข้อมูลการโอนเงิน</h3>
                    </div>
                    <div
                      className={"column"}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 35,
                      }}
                    >
                      {paymentImageUrl ? (
                        <img
                          src={paymentImageUrl || image.rizz_logo}
                          onClick={() =>
                            this.clickImage(paymentImageUrl || image.rizz_logo)
                          }
                          alt="new"
                          style={{
                            width: "6cm",
                            height: "7cm",
                            marginBottom: 10,
                          }}
                        />
                      ) : (
                        <img
                          src={
                            claimImage
                              ? ip + "/image?path=" + claimImage
                              : image.rizz_logo
                          }
                          onClick={() =>
                            this.clickImage(
                              claimImage
                                ? ip + "/image?path=" + claimImage
                                : image.rizz_logo
                            )
                          }
                          alt="new"
                          style={{
                            width: "6cm",
                            height: "7cm",
                            marginBottom: 10,
                          }}
                        />
                      )}
                      <input
                        className="fileInput"
                        style={{ marginLeft: 30 }}
                        type="file"
                        onChange={(e) => this._handleImageChange(e, "PAYMENT")}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <div className={"row-between"} style={{ marginTop: 20 }}>
                        <font style={styles.font}>ธนาคาร</font>
                        <Select
                          style={{ width: "80%", alignItems: "right" }}
                          value={dataPay.bank_pay || "ไทยพาณิชย์"}
                          placeholder="กรุณาเลือกธนาคาร"
                          onChange={(e) => this.onChange(e, "bank_pay")}
                        >
                          {optionBank.map((item, i) => (
                            <Option value={item.value} key={i}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                        {/* <Input
                          style={{ width: "80%", alignItems: "right" }}
                          defaultValue={dataPay.bank_pay || ""}
                          value={dataPay.bank_pay || ""}
                          onChange={(e) =>
                            this.onChange(e.target.value, "bank_pay")
                          }
                        /> */}
                      </div>
                      <div className={"row-between"} style={{ marginTop: 20 }}>
                        <font style={styles.font}>วันที่โอน</font>
                        <DatePicker
                          style={{ width: "80%", alignItems: "right" }}
                          value={moment(dataPay.date_pay || new Date(),'DD/MM/YYYY')}
                          onChange={(date) => this.onChange(date, "date_pay")}
                          format="DD/MM/YYYY"
                        />
                      </div>
                      <div className={"row-between"} style={{ marginTop: 20 }}>
                        <font style={styles.font}>เวลาที่โอน</font>
                        <TimePicker
                          style={{ width: "60%", alignItems: "right" }}
                          onChange={(date) => this.onChange(date, "time_pay")}
                          format="HH:mm"
                          value={moment(
                            dataPay.time_pay || new Date(),
                            "HH:mm"
                          )}
                        />
                      </div>
                      <div className={"row-between"} style={{ marginTop: 20 }}>
                        <font style={styles.font}>จำนวนเงิน</font>
                        <InputNumber
                          style={{ width: "60%", alignItems: "right" }}
                          defaultValue={dataPay.money_pay || 40}
                          value={dataPay.money_pay || 40}
                          onChange={(e) => this.onChange(e, "money_pay")}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={6} push={18}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "flex-end",
                      margin: 15,
                      height: "9.5cm",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        width: "30cm",
                        marginBottom: 20,
                      }}
                    >
                      <BT
                        className="ant-btn"
                        type="danger"
                        onClick={() => this.checkValidate()}
                      >
                        บันทึกข้อมูล
                      </BT>
                    </div>
                  </div>
                </Col>

                <Col span={18} pull={6}>
                  <div
                    style={{
                      border: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#CCCCCC",
                      margin: 15,
                      marginTop: -3,
                    }}
                  >
                    <div style={{ margin: 15, marginLeft: 15 }}>
                      <div className={"row-between"}>
                        <font style={styles.font}>เลขที่การเคลม</font>
                        <font style={styles.font}>{this.state.claimNo}</font>
                      </div>
                      <div className={"row-between"} style={{ marginTop: 10 }}>
                        <font style={styles.font}>รหัสลงทะเบียนสินค้า</font>
                        <font style={styles.font}>{detail.warrantyNo}</font>
                      </div>
                      <div className={"row-between"} style={{ marginTop: 10 }}>
                        <font style={styles.font}>ชื่อสินค้าผลิตภัณฑ์</font>

                        <div
                          style={styles.font}
                          className="editclaimProduct input-number"
                        >
                          {!this.state.isEditProduct ? (
                            <React.Fragment>
                              <font style={styles.font}>{productName}</font>
                              <BT
                                className="btn-editProduct"
                                style={{ backgroundColor: "#ccc" }}
                                onClick={() => this.editProduct(barcode)}
                              >
                                <EditFilled />
                              </BT>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Input
                                value={barcode}
                                onChange={(e) =>
                                  this.onChange(e.target.value, "barcode")
                                }
                                addonBefore={
                                  <p className="label-barcode-editProduct">
                                    {this.state.prepBarcode}
                                  </p>
                                }
                                addonAfter={
                                  <button
                                    className="btn-search-editProduct"
                                    onClick={() => this.searchBarcode(barcode)}
                                  >
                                    <SearchOutlined />
                                  </button>
                                }
                                onInput={(e) => {
                                  e.target.value = e.target.value
                                    .toString()
                                    .slice(0, 9);
                                }}
                                type="number"
                              ></Input>
                              <CloseOutlined
                                className="pd-hz-10"
                                onClick={() =>
                                  this.setState({ isEditProduct: false })
                                }
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className={"row-between"} style={{ marginTop: 10 }}>
                        <font style={styles.font}>วันที่แจ้งเคลม</font>
                        <font style={styles.font}>
                          {moment(detail.claimDate).format("DD-MM-YYYY HH:mm")}
                        </font>
                      </div>
                      <div className={"row-between"} style={{ marginTop: 10 }}>
                        <font style={styles.font}>อาการเสีย</font>
                        {this.state.productTypeID === 99 ||
                        this.state.productTypeID === "99" ||
                        this.state.productTypeIDTemp === 99 ||
                        this.state.productTypeIDTemp === "99" ? (
                          <Input.TextArea
                            style={{ width: "50%", alignItems: "right" }}
                            value={this.state.claimDetail}
                            onChange={(e) =>
                              this.onChange(e.target.value, "claimDetail")
                            }
                            autosize={{ minRows: 2, maxRows: 2 }}
                          />
                        ) : (
                          <Select
                            showSearch
                            className="antSelect"
                            style={{
                              width: "50%",
                              fontSize: "1vw",
                              height: "2vw",
                            }}
                            placeholder="เลือกอาการเสีย"
                            value={this.state.claimDetail}
                            onChange={(e) => this.onChange(e, "claimDetail")}
                          >
                            {this.state.arr_breakdown !== "" &&
                            this.state.arr_breakdown !== null &&
                            this.state.arr_breakdown !== undefined
                              ? this.state.arr_breakdown.map((item) => (
                                  <Option
                                    className="ant-input"
                                    value={
                                      item.breakdownID +
                                      "|" +
                                      item.breakdownName
                                    }
                                  >
                                    {item.breakdownName}
                                  </Option>
                                ))
                              : ""}
                          </Select>
                        )}
                      </div>

                      <div className={"row-between"} style={{ marginTop: 10 }}>
                        <font style={{ ...styles.font }}>
                          หมายเหตุ(เคลมข้ามรุ่น)
                        </font>
                        <Input.TextArea
                          style={{ width: "50%", alignItems: "right" }}
                          defaultValue={claimRemark}
                          value={claimRemark}
                          onChange={(e) =>
                            this.onChange(e.target.value, "claimRemark")
                          }
                          autosize={{ minRows: 1, maxRows: 2 }}
                        />
                      </div>
                      <div
                        className={"column"}
                        style={{
                          marginBottom: 5,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <font style={{ fontSize: ".7vw", marginTop: 70 }}>
                          แจ้งเคลมมาแล้ว {fn.dateDiff(claimdate)} วัน
                        </font>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Modal
              title={
                <p style={{ margin: "0", fontSize: "22px" }}>
                  <i style={{ margin: "0 10px", color: "#ffc107" }}>
                    <WarningOutlined />
                  </i>
                  ยืนยันการแก้ไข
                </p>
              }
              visible={this.state.isConfirmEdit}
              onOk={() => this.confirmEditProduct()}
              onCancel={this.closeConfirmEdit}
              footer={[
                <BT
                  key="submit"
                  style={{ backgroundColor: "#4bad1a" }}
                  loading={loading}
                  onClick={this.confirmEditProduct}
                >
                  ตกลง
                </BT>,
                <BT key="back" onClick={this.closeConfirmEdit}>
                  ยกเลิก
                </BT>,
              ]}
              centered
            >
              <div style={{ textAlign: "center" }}>
                <font style={{ fontSize: "18px" }}>
                  <b style={{ margin: "0 10px" }}>สินค้าเดิม:</b>
                  <span style={{ color: "#aaa" }}>
                    {this.state.productName}
                  </span>
                </font>
                <br />
                <font style={{ fontSize: "18px", color: "blue" }}>
                  <b style={{ margin: "0 10px" }}>
                    <EnterOutlined className="rot-y-180" />
                  </b>
                  <span>{this.state.productNameTemp}</span>
                </font>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px",
                }}
              >
                <img
                  style={{ maxHeight: "30vh", borderRadius: "20px" }}
                  src={
                    this.state.productImageTemp !== "" &&
                    this.state.productImageTemp !== null &&
                    this.state.productImageTemp !== undefined
                      ? ip + "/" + this.state.productImageTemp
                      : image.rizz_logo
                  }
                />
              </div>
            </Modal>
            <Modal
              title={
                <font style={{ fontSize: "22px" }}>
                  {this.state.modalMessage.regMsgTitle}
                  <span
                    style={{
                      color: this.state.modalMessage.color,
                      marginLeft: "10px",
                    }}
                  >
                    {this.state.modalMessage.status}
                  </span>
                </font>
              }
              visible={this.state.isConfirmShow}
              onCancel={this.closeConfirm}
              centered
              footer={[
                <Row key="save">
                  {!this.state.isRegSuccess ? (
                    <React.Fragment>
                      <BT
                        key="submit"
                        style={{ backgroundColor: "#52c41a" }}
                        loading={loading}
                        onClick={() => this.updateClaim()}
                      >
                        ใช่
                      </BT>
                      ,
                      <BT key="back" onClick={this.closeConfirm}>
                        ไม่ใช่
                      </BT>
                    </React.Fragment>
                  ) : (
                    <BT
                      onClick={this.closeConfirm}
                      style={{
                        width: "5vw",
                        height: "10vw",
                        background: "red",
                        color: "#FFFFFF",
                      }}
                    >
                      ตกลง
                    </BT>
                  )}
                </Row>,
              ]}
            >
              <fon style={{ fontSize: "18px" }}>
                {this.state.modalMessage.regMsg}
              </fon>
            </Modal>
          </Form>
        )}
      </React.Fragment>
    );
  }
}

export default EditClaimScene;
