import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { observer } from "mobx-react";
// import Roots from './routes/Root';
import Root from "./routes/Root";
import LoginScene from "./scene/LoginScene";
import BillShippingScene from "./scene/Rizz/Claim/BillShippingScene";
// import FocusBillShippingScene from "./scene/Focus/Claim/FocusBillShippingScene";

@observer
class App extends Component {
  render() {
    return (
      <Router>
        <Route path={"/login"} component={LoginScene} />
        {/* <Route path={'/login'} component={LoginScene} /> */}
        <Route path={"/rizz/billshipping"} component={BillShippingScene} />
        {/* <Route path={'/focus/billshipping'} component={FocusBillShippingScene} /> */}

        <Root />
      </Router>
    );
  }
}

export default App;
