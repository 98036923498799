export const image = {
  rizz_logo: require("./rizz_logo.jpg"),
  brandblack: require("./brandblack.jpg"),
  brandwhite: require("./brandwhite.jpg"),
  calendar: require("./calendar.png"),
  focus_brand: require("./focus_logo.png"),
  focus: require("./logo.png"),
  logo_focusform: require("./focus_logoreport.png"),
  noImage: require("./no-image.jpg"),
};
