import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import { observer } from "mobx-react";
import autobind from "autobind-decorator";
import { BtnGoback } from "../../../components/Button";
import { Font } from "../../../styles/styled";
import {
  Icon,
  Input,
  Row,
  Col,
  Button as BT,
  Spin,
  Modal,
  message,
} from "antd";
import { color } from "../../../resource";
import {
  GET,
  POST,
  RIZZ_GET_DETIAL_BY_CLAIMNO,
  RIZZ_UPDATE_WARRABTY,
  RIZZ_INSERT_EMS,
  //   FOCUS_DETAILCLAIM,
  //   FOCUS_WARRANTY_ID,
  //   FOCUS_UNACTIVE,
  //   FOCUS_EMS,
  //   FOCUS_UPDATECLAIM_STATUS,
  //   FOCUS_UPDATEWARRABTY,
} from "../../../service";
import moment from "moment";

const styles = {
  font: {
    fontSize: "0.4cm",
    marginTop: 3,
    marginBottom: 5,
    marginLeft: 5,
  },
};

@observer
class MultiClaimScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hiddenform: true,
      errordecode: true,
      errorclaim: false,
      claimdoc: "",
      emsdoc: "",
      decode: "",
      item_code: "",
      claim_colect: "",
      claim_colect_color: "",
      decode_colect: "",
      decode_colect_color: "",
      shipping_date: moment(),

      user: {
        name: "",
        telephone: "",
        brandmobile: "",
        modelmobile: "",
      },
      address: {
        address: "",
      },
      product: {
        product_name: "",
      },
    };
  }
  componentDidMount() {}

  @autobind
  goBack() {
    this.props.history.goBack();
  }
  @autobind
  onKeyPressSearch(event, type, next_event = "") {
    if (event.key === "Enter") {
      // type === 'claimdoc' ? this.onSearch(next_event) : this.checkWarranty(next_event_)
      switch (type) {
        case "claimdoc":
          this.onSearch(next_event);
          break;
        case "emsdoc":
          this.refs[next_event].focus();
          break;
        case "item_code":
          this.refs[next_event].focus();
          break;
        case "decode":
          //   this.checkWarranty();
          break;
      }
    }
  }
  @autobind
  async confirm() {
    const {
      errordecode,
      errorclaim,
      emsdoc,
      decode,
      no_warranty,
      shipping_date,
      user,
      status,
      type_claim,
      item_code,
      claimdoc,
    } = this.state;
    let user_id = user.user_id;
    let telephone = user.telephone;
    if (!emsdoc) {
      return message.error("กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนการบันทึก");
    } else if (status !== 3) {
      Modal.error({
        title: "กรุณาตรวจสอบสถานะก่อนการส่ง",
        content: "สถานะดังกล่าวไม่สามารถจัดส่งได้",
      });
    } else {
      this.setState({ loading: true });
      // try {
      //   let res = await POST(RIZZ_UPDATE_WARRABTY, { no_warranty });
      //   if (res.success) {
      try {
        let res_tracking = await POST(RIZZ_INSERT_EMS, {
          tracking: emsdoc,
          shippingDate: shipping_date,
          claimNo: claimdoc,
        });
        if (res_tracking.success) {
          this.setState({ loading: false, claimdoc: "" });
          this.clear();
          Modal.success({
            title: "บันทึกข้อมูลสำเร็จ",
            content: "ระบบได้ทำการส่งข้อความการจัดส่ง เรียบร้อยแล้ว",
          });
        }
      } catch (error) {
        console.log(error);
      }

    }
  }
  

  @autobind
  async onSearch(next_event) {
    const { claimdoc } = this.state;
    try {
      let res = await GET(RIZZ_GET_DETIAL_BY_CLAIMNO + claimdoc);

      if (res.success) {

        let {
          customerName,
          address,
          productName,
          claimRemark,
          claimStatus,
          warrantyNo,
          telephone,
          claimDetail,
        } = res.result;
        this.setState({
          user: { name: customerName, note: claimRemark, telephone },
          address: { address_name: address },
          product: { product_name: productName, claimDetail },
          status: claimStatus,
          no_warranty: warrantyNo,
          claim_colect: "check-circle",
          claim_colect_color: "certain-category-icon-colect",
          error_color_claim: "green",
          errorclaim_detail: "",
          errorclaim: false,
          status_detail: this.checkstatus(claimStatus),
          // type_claim: claim.type,
          // item_code: product.itemcode,
        });
        this.refs[next_event].focus();
      }
      // }
    } catch (error) {
      console.log(error);
      this.setState({
        errorclaim_detail: "ไม่พบชุดเอกสารดังกล่าว",
        claim_colect: "close-circle",
        claim_colect_color: "certain-category-icon-uncolect",
        error_color_claim: "red",
        errorclaim: true,
      });
    }
  }
  @autobind
  checkstatus(status) {
    switch (status) {
      case 1:
        return "รอชำระเงิน";

      case 2:
        return "รอการตรวจสอบ";

      case 3:
        return "กำลังเตรียมจัดส่ง";

      case 4:
        return "จัดส่งเรียบร้อย";

      case 99:
        return "ไม่อนุมัติ";
    }
  }
  @autobind
  onChange(e, ref) {
    this.setState({
      [ref]: e,
    });
    if (ref === "claimdoc") {
      this.clear();
    }
  }
  @autobind
  clear() {
    this.setState({
      user: {
        name: "",
        telephone: "",
        brandmobile: "",
        modelmobile: "",
      },
      address: {
        address: "",
      },
      product: {
        product_name: "",
      },
      type_claim: "",
      errorclaim_detail: "",
      claim_colect: "",
      claim_colect_color: "",
      error_color_claim: "",
      errorclaim: false,
      decode_colect: "",
      decode_colect_color: "",
      error_color_decode: "",
      status_detail: "",
      emsdoc: "",
      decode: "",
      item_code: "",
      errordecode: false,
    });
  }

  render() {
    const {
      hiddenform,
      claimdoc,
      emsdoc,
      decode,
      claim_colect,
      decode_colect,
      user,
      address,
      product,
      claim_colect_color,
      decode_colect_color,
      errordecode,
      errordecode_detail,
      errorclaim,
      errorclaim_detail,
      loading,
      item_code,
    } = this.state;
    const theme = {
      hidden: hiddenform,
    };
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }

    return (
      <div labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <BtnGoback title={"กลับ"} onClick={() => this.goBack()}></BtnGoback>
        <Row>
          <Col span={14} push={10}>
            <div className={"border-claim"} style={{ margin: 15 }}>
              <div className={"column"} style={{ margin: 10 }}>
                <h3>ข้อมูลเอกสาร</h3>
              </div>

              <ThemeProvider theme={theme}>
                <div
                  className={"column"}
                  style={{ margin: 15, marginTop: -10, marginLeft: 25 }}
                >
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="เลขที่เคลม" />
                    <Font text={claimdoc} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="ชื่อ-นามสกุล" />
                    <Font text={user.name} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="หมายเลขโทรศัพท์" />
                    <Font text={user.telephone} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="ที่อยู่" style={{ width: "35%" }} />

                    <br />
                    <Font text={address.address_name} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="รุ่นสินค้า" />
                    <Font text={product.product_name} />
                  </div>

                  {/* <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="รุ่นมือถือ" />
                    <Font text={user.brandmobile} />
                  </div> */}
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="อาการเสีย" />
                    <Font text={product.claimDetail} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="หมายเหตุ" />
                    <Font text={user.note} />
                  </div>
                  {/* <div
                    className={"row-between"}
                    style={{
                      marginTop: 15,
                      marginBottom: 25,
                      color: this.state.status === 4 ? "green" : "",
                    }}
                  >
                    <Font text="สถานะการเคลม" />
                    <Font text={this.state.type_claim} />
                  </div> */}
                  <div
                    className={"row-between"}
                    style={{
                      marginTop: 15,
                      marginBottom: 25,
                      color: this.state.status === 4 ? "green" : "",
                    }}
                  >
                    <Font text="สถานะ" />
                    <Font text={this.state.status_detail} />
                  </div>
                </div>
              </ThemeProvider>
            </div>
          </Col>
          <Col span={10} pull={14}>
            <div className={"border-claim"} style={{ margin: 15 }}>
              <div className={"column"} style={{ margin: 10 }}>
                <h3>ข้อมูลการยิงจ่ายงาน</h3>
              </div>

              <div className={"column-claim-cr"}>
                <font style={styles.font}>
                  <b style={{ color: "red" }}>* </b>เลขที่เอกสาร
                </font>
                <Input
                  suffix={
                    <Icon
                      type={claim_colect}
                      style={{ fontSize: "16px" }}
                      className={claim_colect_color}
                    />
                  }
                  style={{ width: "70%" }}
                  placeholder="เลขที่เอกสาร"
                  value={claimdoc}
                  autoFocus
                  onKeyPress={(e) =>
                    this.onKeyPressSearch(e, "claimdoc", "emsdoc")
                  }
                  onChange={(e) => this.onChange(e.target.value, "claimdoc")}
                />

                <font
                  style={{
                    fontSize: "0.3cm",
                    color: this.state.error_color_claim,
                    marginTop: 5,
                    marginLeft: 5,
                  }}
                  hidden={!errorclaim}
                >
                  {errorclaim_detail}
                </font>
              </div>
              <div className={"column-claim-cr"} style={{ marginTop: -5 }}>
                <font style={styles.font}>
                  <b style={{ color: "red" }}>* </b>เลขที่พัสดุ
                </font>
                <Input
                  style={{ fontSize: "16px", width: "70%" }}
                  className={claim_colect_color}
                  placeholder="เลขที่พัสดุ"
                  ref="emsdoc"
                  value={emsdoc}
                  onKeyPress={(e) =>
                    this.onKeyPressSearch(e, "emsdoc", "item_code")
                  }
                  onChange={(e) => this.onChange(e.target.value, "emsdoc")}
                />
              </div>
            </div>
          </Col>
          <Col span={10} pull={14}>
            <div style={{ margin: 15, marginTop: 20 }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  margin: 15,
                }}
              >
                <BT
                  style={{
                    width: "10vw",
                    height: "20vw",
                    background: "#FFFFFF",
                  }}
                  onClick={() => this.clear()}
                  hidden={!claimdoc}
                >
                  เคลียร์ข้อมูล
                </BT>
                <BT
                  style={{
                    width: "10vw",
                    height: "20vw",
                    background: color.rizz_primary,
                    color: "#FFFFFF",
                  }}
                  onClick={() => this.confirm()}
                >
                  บันทึก
                </BT>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MultiClaimScene;
