import React, { Component } from 'react'
import { observer } from 'mobx-react';
import { Table, Input, Icon, Button, DatePicker, message, Modal } from 'antd';
import { columnTableReportWarranty } from '../../../resource';
import autobind from 'autobind-decorator';
import { ip, POST, RIZZ_GET_REPORT_WARRANTY, RIZZ_EXPORT_REPORT, APPROVE_WARRANTY_NO } from '../../../service';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
let defaultData = []

@observer
class HistoryScene extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingData: false,
            searchText: '',
            loading: true,
            // start_date: moment().format('YYYY-MM-DD'),
            // end_date: moment().format('YYYY-MM-DD'),
            start_date: moment().clone().startOf("month").format("YYYY-MM-DD"),
            end_date: moment().clone().endOf("month").format("YYYY-MM-DD"),
        }
    }

    componentDidMount() {
        this.onFetchHistory(this.state.start_date, this.state.end_date)
    }

    @autobind
    async onFetchHistory(dateStart, dateEnd) {
        try {
            let body = {
                warrantyDateFrom: dateStart,
                warrantyDateTo: dateEnd
            }
            const res = await POST(RIZZ_GET_REPORT_WARRANTY, body);
            let data = [];
            if (res.success) {
                data = res.result.map((el, i) => {
                    return {
                        ...el,
                        key: i + 1,
                        no: el.rowNo,
                        warrantyNo: el.warrantyNo,
                        customerName: el.customerName,
                        productName: el.productName,
                        brandMobileName: el.brandMobileName,
                        modelMobileName: el.modelMobileName,
                        customerTelephone: el.customerTelephone,
                        reportWarrantyStatus: el.reportWarrantyStatus,

                    }
                })
            }

            defaultData = data;

            this.setState({ isLoadingData: false, data: defaultData, loading: false,searchText:'' })
        } catch (error) {
            this.setState({ isLoadingData: false })
        }
    }
    @autobind
    changeFormatDate(date) {
        if (date == '') {
            return ''
        }
        else {
            let dateTemp = date.split('-')
            return `${dateTemp[2]}-${dateTemp[1]}-${dateTemp[0]}`
        }
    }

    @autobind
    onChange(e, ref) {
        this.setState({ [ref]: e })
    }

    @autobind
    onKeyPressSearch(event) {
        if (event.key === 'Enter') {
            this.onSearch()
        }
    }

    @autobind
    onSearch() {
        const { searchText } = this.state;
        if (!searchText) {
            return this.setState({ data: defaultData })
        }

        const d = defaultData.filter(el => {
            return Object.values(el).some(val => {
                return val ? val.toString().toLowerCase().includes(searchText.toLowerCase()) : false
            })
        })

        this.setState({ data: d })
    }

    @autobind
    async exportReport() {
        try {
            let body = {
                warrantyDateFrom: this.state.start_date,
                warrantyDateTo: this.state.end_date
            }
            const r = await POST(RIZZ_EXPORT_REPORT, body)
            if (r.success) {
                message.success()
                window.open(ip + r.result.pathFile)
            }
            else {
                message.error('เกิดข้อผิดพลาด');
            }
        } catch (err) {
            message.error(`เกิดข้อผิดพลาด (${err.message})`);
        }
    }

    @autobind
    async onChangeRangePicker(dates) {
        let start = moment(dates[0]).format('YYYY-MM-DD')
        let end = moment(dates[1]).format('YYYY-MM-DD')

        this.setState({
            start_date: start,
            end_date: end,
        })
        this.onFetchHistory(start, end)
    }

    confirmAppoveWarranty = (record) => {
        Modal.confirm({
            title: "ยืนยันตัดรับประกันสินค้า",
            icon: <ExclamationCircleOutlined />,
            content: `เลขที่ลงทะเบียน ${record.warrantyNo}`,
            onOk: () => this.appoveWarranty(record.warrantyNo),
            okText: "ยืนยัน",
            cancelText: "ยกเลิก",
        });

    }

    appoveWarranty = async (warrantyNo) => {
        try {

            const res = await POST(APPROVE_WARRANTY_NO, {warrantyNo})
            const {success,result} = res
            const {start_date,end_date} = this.state
            if (success) {
                Modal.success({title : `${result.message}`})
                this.onFetchHistory(start_date, end_date)
            }
        } catch (err) {
            message.error(`เกิดข้อผิดพลาด (${err.message})`);
        }

    }

    @autobind
    goDetail(record) {
        window.open(`/rizz/claim_detail?id=${record.claimNo}`, "_blank");
    }

    render() {
        const { data, isLoadingData, searchText } = this.state;

        return (
            <div>
                {this.state.loading ?
                    <div>
                        <Icon style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '6vw' }} type='loading' />
                    </div>
                    :
                    <div className={'column'}>
                        {/*###################### HEAD ######################*/}
                        <div className={'row-between'}>
                            <Button style={{ backgroundColor: '#32CD32' }} onClick={() => this.exportReport()}>EXPORT</Button>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                                <Input.Search
                                    style={{ width: '20vw', borderRadius: '10vw', paddingLeft: '1vw', fontSize: '32px' }}
                                    placeholder={'ค้นหาข้อมูล'}
                                    value={searchText}
                                    onKeyPress={e => this.onKeyPressSearch(e)}
                                    onSearch={() => this.onSearch()}
                                    onChange={e => this.onChange(e.target.value, 'searchText')}
                                />
                            </div>
                        </div>
                        <div className={'row-between'}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                                <DatePicker.RangePicker
                                    //value={moment(new Date())}
                                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                                    format="YYYY-MM-DD"
                                    defaultValue={[moment(this.state.start_date), moment(this.state.end_date)]}
                                    onChange={this.onChangeRangePicker}
                                    size='default' />
                            </div>
                        </div>


                        {/*###################### TABLE ######################*/}
                        <Table
                            title={() => <h2>รายงานการลงทะเบียน</h2>}
                            style={{ cursor: "pointer" }}
                            columns={columnTableReportWarranty(
                                (record) => window.open(`/rizz/warranty_detail?warrantyNo=${record.warrantyNo}`, "_blank"),
                                (record) => this.confirmAppoveWarranty(record)
                            )}
                            rowClassName={(record, i) => i % 2 === 1 ? 'even' : ''}
                            dataSource={data}
                            bordered
                            pagination={{ pageSize: 15 }}
                            loading={isLoadingData}
                            size="small"
                        />
                    </div>
                }
            </div>
        )
    }


    renderExpand(record) {

        return <div style={{ padding: '1vw', display: 'flex', alignItems: 'center' }} >
            <img src={ip + '/' + record.claimImage} style={{ width: 100, height: 100 }} className={'contain'} />
            <div style={{ marginLeft: '1vw' }}>
                <div style={{ display: 'flex', marginBottom: '-12px' }}>
                    <p style={{ margin: 0, width: '100px' }} >เลขที่การเคลม</p>
                    <p>{record.claimNo}</p>
                </div>
                <div style={{ display: 'flex', marginBottom: '-12px' }}>
                    <p style={{ margin: 0, width: '100px' }} >เลขที่ลงทะเบียน</p>
                    <p>{record.warrantyNo}</p>
                </div>
                <div style={{ display: 'flex', marginBottom: '-12px' }}>
                    <p style={{ margin: 0, width: '100px' }} >ชื่อสินค้า</p>
                    <p>{record.productName}</p>
                </div>
                <div style={{ display: 'flex', marginBottom: '-12px' }}>
                    <p style={{ margin: 0, width: '100px' }} >วันที่แจ้งเคลม</p>
                    <p>{record.claimDate}</p>
                </div>
                <div style={{ display: 'flex', marginBottom: '-12px' }}>
                    <p style={{ margin: 0, width: '100px' }} >อาการเสีย</p>
                    <p>{record.claimDetail}</p>
                </div>
            </div>
        </div>
        // return record.map((el, i) => {
        // return <div style={{ padding: '1vw', display: 'flex', alignItems: 'center' }} key={`${el}${i}`}>
        //     <img src={el.claim_image} style={{ width: 100, height: 100 }} className={'contain'} />
        //     <div style={{ marginLeft: '1vw' }}>
        //         <div style={{ display: 'flex', marginBottom: '-12px' }}>
        //             <p style={{ margin: 0, width: '100px' }} >เลขที่การเคลม</p>
        //             <p>{el.claim_id}</p>
        //         </div>
        //         <div style={{ display: 'flex', marginBottom: '-12px' }}>
        //             <p style={{ margin: 0, width: '100px' }} >วันที่แจ้งเคลม</p>
        //             <p>{el.create_at}</p>
        //         </div>
        //         <div style={{ display: 'flex', marginBottom: '-12px' }}>
        //             <p style={{ margin: 0, width: '100px' }} >อาการเสีย</p>
        //             <p>{el.description}</p>
        //         </div>
        //     </div>
        // </div>
        // })
    }
}

export default HistoryScene