import React from 'react';
import { ColumnStyleStatus, ColumnStylestatusOfFocus, ColumnStylestatusOfFocusTypeCliam, ColumnStylestatusOfFocusImportAx, ColumnStylestatusOfStore, ColumnStylestatusOfDealer, ColumnStyleStatusReportWarranty } from '../styles/styled';
import { STATUS_CLAIM, STATUS_REPORT_REGISTER, STATUS_CLAIM_FOCUS, STATUS_STORE_CLAIM, STATUS_IMPORTAX_FOCUS, STORE_FOCUS, STATUS_CLAIM_DEALER } from '../resource';

export const ColumnStatus = ({ status }) => {
    
    const findIndex = STATUS_CLAIM.findIndex(el => el.status_id === +status)

    return <ColumnStyleStatus status={status}>
        <div style={{ padding: '8px', color: '#EEE' }}>
            {STATUS_CLAIM[findIndex].status_name}
        </div>
    </ColumnStyleStatus>
}
export const ColumnStatus_FOCUS = ({ status, type }) => {

    const findIndex = type === 'STORE' ? STATUS_CLAIM_DEALER.findIndex(el => el.status_id === +status) : STATUS_CLAIM_FOCUS.findIndex(el => el.status_id === +status)

    if (type === 'STORE') {

        return <ColumnStylestatusOfDealer status={findIndex}>
            <div style={{ padding: '8px' }}>
                {STATUS_CLAIM_DEALER[findIndex].status_name}
            </div>
        </ColumnStylestatusOfDealer>
    } else {

        return <ColumnStylestatusOfFocus status={findIndex}>
            <div style={{ padding: '8px' }}>
                {STATUS_CLAIM_FOCUS[findIndex].status_name}
            </div>
        </ColumnStylestatusOfFocus>
    }

}
export const ColumnStatusReportWarranty = ({ status }) => {
    
    const findIndex = STATUS_REPORT_REGISTER.findIndex(el => el.status_id === +status)

    return <ColumnStyleStatusReportWarranty status={status}>
        <div style={{ padding: '8px', color: '#EEE' }}>
            {STATUS_REPORT_REGISTER[findIndex].status_name}
        </div>
    </ColumnStyleStatusReportWarranty>
}

export const ColumnStore_type = ({ type }) => {
    const findIndex = STORE_FOCUS.findIndex(el => el.original_name === type)
    //return STORE_FOCUS[findIndex].thai_name
    return <ColumnStylestatusOfStore status={findIndex}>
        <div style={{ padding: '8px' }}>
            {STORE_FOCUS[findIndex].thai_name}
        </div>
    </ColumnStylestatusOfStore>
}
export const ColumnStatus_ImportAx_Focus = ({ status }) => {
    const findIndex = STATUS_IMPORTAX_FOCUS.findIndex(el => el.status_id === +status)
    return <ColumnStylestatusOfFocusImportAx status={+status}>
        <div style={{ padding: '8px' }}>
            {STATUS_IMPORTAX_FOCUS[findIndex].status_name}
        </div>
    </ColumnStylestatusOfFocusImportAx>

}
export const ColumnStatus_TypeClaim = ({ status }) => {
    const findIndex = STATUS_STORE_CLAIM.findIndex(el => el.status_name === status)
    return <ColumnStylestatusOfFocusTypeCliam status={findIndex}>
        {/* <div style={{ padding: '8px' }}>
            {STATUS_STORE_CLAIM[findIndex].status_name}
        </div> */}
    </ColumnStylestatusOfFocusTypeCliam>
}