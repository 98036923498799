import React, { Component } from 'react'
import { observer } from 'mobx-react';
import QRCode from 'qrcode.react';
import autobind from 'autobind-decorator';
import { decryptParameter } from '../../../functions/functions';
import { POST, RIZZ_GETREPORT } from '../../../service';
import { Spin, Table } from 'antd';
import { image, columnTableReport } from '../../../resource';
import { Button } from '../../../components/Button';

@observer
class BillShippingScene extends Component {
    constructor(props) {
        super(props)
        this.state = {
            print: false,
            data: []
        }
    }

    async componentDidMount() {
        await this.setState({ detail: this.props.location.state.item } ,()=> console.log('detail_bill',this.state.detail))
        window.onbeforeprint = () => {
            this.setState({ print: true })
        };
        window.onafterprint = () => {
            this.setState({ print: false })
        };
    }

    getStateForEdit() {
        const { detail } = this.state;
        this.setState({ item: detail },()=> console.log('item:',this.state.item))    
    }

    @autobind
    async onFetchReport() {
        try {
            const { claim_id } = JSON.parse(decryptParameter(this.props.location.state.id))
            const res = await POST(RIZZ_GETREPORT, { claim_id: JSON.stringify(claim_id) })
            if (res.success) {
                this.setState({ loading: false, data: res.result })
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { loading, print,detail } = this.state;
        if (loading) {
            return <div className="example">
                <Spin />
            </div>
        }
        return (
            !!detail?
            <div className={'body-print'}>
                {detail.map((el, i) => {
                    const { claimRemark, customerName, productName, telephone, shiptoAddress, claimNo } = el;
                    return <div className={'container-bill'} key={i}>

                        <div className={'row'} style={{ fontSize: '0.4cm', marginTop: 50 }}>
                            <img src={image.rizz_logo} className={'image-logo'} alt={''} />
                            <div className={'column'} style={{ marginLeft: '0.5cm' }}>
                                <font style={{ fontSize: '0.4cm' }}>บริษัท ดีพลัสอินเตอร์เทรด จำกัด</font>
                                <font style={{ fontSize: '0.4cm' }}>123/20-22 ถ.นนทรี แขวงช่องนนทรี</font>
                                <font style={{ fontSize: '0.4cm' }}>เขตยานนาวา กทม 10120 โทร 02 294484 8</font>
                            </div>
                        </div>
                        <div style={{ marginTop: 30 }} className={'content-section1'}>
                            <font >ชื่อที่อยู่/ผู้รับ</font>
                            <font>จัดส่งคุณ: {customerName}</font>
                            <font>ที่อยู่: {shiptoAddress}
                            </font>
                            <font>เบอร์โทรศัพท์: {telephone}</font>
                            <font>สินค้า:{productName} </font>
                            {el.note && <font>หมายเหตุ:{claimRemark} </font>}
                            <div style={{ alignSelf: 'flex-end' }}>
                                <QRCode
                                    value={claimNo}
                                    size={120}
                                />
                            </div>
                        </div>
                    </div>
                })}
                {!print && <div style={{ textAlign: 'center', marginTop: '1cm', marginBottom: '1cm' }}>
                    <Button title={'ยกเลิก'}
                        type={'secondary'}
                        onPress={() => this.props.history.goBack()} />
                    <Button title={'PRINT'}
                        onPress={() => this.setState({ print: true }, () => window.print())} />
                </div>}
            </div>
            :
            <div>loading...</div>
        )
    }
}

export default BillShippingScene