import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Input, Row, Col, Button as BT, Modal, Select, DatePicker } from 'antd'
import { POST, GET, GET_OPTION_BRAND_MOBILE, RIZZ_UPDATE_WARRABTY, RIZZ_GETDETIALBYBARCODE, GET_WARRANTY_BY_WARRANTYNO, GET_OPTION_CHANNEL } from '../../../service'
import { SearchOutlined } from '@ant-design/icons';
import { BtnGoback } from "../../../components/Button";
import { Label } from '../../../styles/styled';
import moment from "moment";
import dayjs from 'dayjs';

@observer
class EditWarrantyScene extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prepBarcode: '8859',
            barcode: "",
            dataWarranty: {},
            optionBrand: [],
            optionChannel: []
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const warrantyNo = query.get("warrantyNo")
        this.onGetData(warrantyNo)
        this.onGetOptionBrand()
        this.getChannel()
    }


    async onGetData(warrantyNo) {
        try {
            const res = await GET(GET_WARRANTY_BY_WARRANTYNO(warrantyNo))
            const { success, result } = res
            if (success) {
                this.setState({ dataWarranty: result, barcode: result.barcode.slice(4) })
            }
        } catch (error) {
            Modal.error({ title: error.message })
        }
    }

    async onGetOptionBrand() {
        try {
            const res = await GET(GET_OPTION_BRAND_MOBILE)
            const { success, result } = res
            if (success) {
                const brand = result.map((val, i) => {
                    return {
                        label: val.brandName,
                        value: val.brandID,
                    };
                });
                this.setState({ optionBrand: brand })
            }
        } catch (error) {
            Modal.error({ title: error.message })
        }
    }

    async getChannel() {
        try {
            const res = await GET(GET_OPTION_CHANNEL)
            const { success, result } = res
            if (success) {
                this.setState({ optionChannel: result })
            }
        } catch (err) {
            Modal.error(`เกิดข้อผิดพลาด getChannel: ${err.message}`)
        }
    }


    async handleSubmit() {
        try {
            const { dataWarranty, barcode, prepBarcode, optionBrand } = this.state
            const { warrantyNo, customerName, productCode, productID, productName, productTypeID, productTypeName, modelMobileName, brandMobileID, endDate, channelId } = dataWarranty
            const fullbarcode = `${prepBarcode}${barcode}`
            const brandMobileName = optionBrand.filter((item) => item.value === brandMobileID)[0].label
            console.log({ optionBrand, brandMobileName, brandMobileID })

            const payload = {
                warrantyNo,
                barcode: fullbarcode,
                customerName,
                productCode,
                productID,
                productName,
                productTypeID,
                productTypeName,
                brandMobileID,
                modelMobileName,
                brandMobileName,
                endDate,
                channelId
            }

            console.log({ payload })
            const res = await POST(RIZZ_UPDATE_WARRABTY, payload)
            const { success, message } = res
            if (success) {
                Modal.success({ title: message })
            }
        } catch (error) {
            Modal.error({ title: error.message })
        }
    }

    async searchDetailByBarcode() {
        try {
            const { barcode, prepBarcode, dataWarranty } = this.state
            const fullbarcode = `${prepBarcode}${barcode}`;
            const res = await GET(RIZZ_GETDETIALBYBARCODE + fullbarcode);
            const { success, result } = res
            if (success) {
                const { productCode, productID, productName, productTypeID, productTypeNameTH } = result
                dataWarranty.productCode = productCode
                dataWarranty.productID = productID
                dataWarranty.productName = productName
                dataWarranty.productTypeID = productTypeID
                dataWarranty.productTypeName = productTypeNameTH

                this.setState({ dataWarranty })
            }
        } catch (err) {
            Modal.error({ title: err.message })
        }

    }


    handleChange(value, ref) {
        const { dataWarranty } = this.state
        if (ref === 'endDate') {
            dataWarranty[ref] = moment(value).format('YYYY-MM-DD')
        } else {
            dataWarranty[ref] = value
        }

        this.setState({ dataWarranty })
    }



    render() {
        const { prepBarcode, barcode, dataWarranty, optionBrand, optionChannel } = this.state
console.log({dataWarranty})
        return (

            <div>
                <BtnGoback title={"กลับ"} onClick={() => this.goBack()} />
                <h2 style={{ marginTop: "18px" }}>{"แก้ไขข้อมูลรับประกัน"}</h2>
                <Row>
                    <Col span={10} className='block-edit-warranty' style={{ marginRight: '20px' }}>
                        <p style={{ fontSize: '18px' }}><b>{"ข้อมูลลูกค้า"}</b></p>
                        <div className='space-input'>
                            <label className="label">{"เบอร์โทรศัพท์ *"}</label>
                            <Input
                                type="text"
                                value={dataWarranty.customerTelephone}
                                disabled
                            /></div>
                        <div className='space-input'><label className="label">{"ชื่อ-นามสกุล *"}</label>
                            <Input
                                type="text"
                                value={dataWarranty.customerName}
                                onChange={(e) => this.handleChange(e.target.value, "customerName")}
                            /></div>
                        <div className='space-input'><label className="label">{"ยี่ห้อมือถือ"}</label>
                            <br />
                            <Select
                                style={{ width: "100%", marginRight: "10px" }}
                                placeholder="เลือกยี่ห้อมือถือ"
                                value={dataWarranty.brandMobileID}
                                onChange={(e) => this.handleChange(e, "brandMobileID")}
                            >
                                {optionBrand.map((item, i) => (
                                    <Select.Option value={item.value} key={i}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className='space-input'><label className="label">{"รุ่นมือถือ"}</label>
                            <Input
                                type="text"
                                value={dataWarranty.modelMobileName}

                            /></div>
                    </Col>
                    <Col span={13} className='block-edit-warranty'>
                        <p style={{ fontSize: '18px' }}><b>{"ข้อมูลสินค้ารับประกัน"}</b></p>
                        <div className='space-input'>
                            <label className="label">{"เลขที่รับประกัน"}</label>
                            <Input
                                type="text"
                                value={dataWarranty.warrantyNo}
                                disabled
                            />
                        </div>
                        <div className='space-input'>
                            <label className="label">{"Barcode"}</label>
                            <Input
                                addonBefore={<p className="label-barcode-editProduct">{prepBarcode}</p>}
                                addonAfter={<button className="btn-search-editProduct" onClick={() => this.searchDetailByBarcode()}><SearchOutlined /></button>}
                                className="input-barcode-editProduct"
                                onInput={(e) => {
                                    e.target.value = e.target.value.toString().slice(0, 9)
                                }}
                                type="number"
                                placeholder="9 หลักสุดท้าย"
                                value={barcode}
                                onChange={(e) => this.setState({ barcode: e.target.value })}
                            />
                        </div>
                        <div className='space-input'>
                            <label className="label">{"ชื่อสินค้า"}</label>
                            <Input
                                type="text"
                                value={dataWarranty.productName}
                                disabled
                            />
                        </div>
                        <div className='space-input'>
                            <label className="label">{"ประเภทสินค้า"}</label>
                            <Input
                                type="text"
                                value={dataWarranty.productTypeName}
                                disabled
                            />
                        </div>
                        <div className='space-input'>
                            <label className="label">{"ช่องทางการซื้อ"}</label>
                            <br />
                            <Select
                                style={{ width: "100%", marginRight: "10px" }}
                                placeholder="กรุณาเลือกช่องทางการซื้อ"
                                value={dataWarranty.channelId}
                                onChange={(e) => this.handleChange(e, "channelId")}
                            >
                                {optionChannel.map((item, i) => (
                                    <Select.Option value={item.value} key={i}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className='space-input'>
                            <Label className="label">{"วันเริ่มต้นรับประกัน"}</Label>
                            <br />
                            <DatePicker
                                style={{ width: "100%" }}
                                value={moment(dataWarranty.startDate, 'YYYY-MM-DD')}
                                format={"DD/MM/YYYY"}
                                onChange={(date) => this.handleChange(date, "startDate")}
                                disabled
                            />
                        </div>
                        <div className='space-input'>
                            <Label className="label">{"วันสิ้นสุดรับประกัน"}</Label>
                            <br />
                            <DatePicker
                                style={{ width: "100%" }}
                                value={moment(dataWarranty.endDate, 'YYYY-MM-DD')}
                                format={"DD/MM/YYYY"}
                                onChange={(date) => this.handleChange(date, "endDate")}
                            />
                        </div>
                    </Col>

                    <BT onClick={() => this.handleSubmit()} className='btn-update'  >
                        แก้ไข
                    </BT>
                </Row>
            </div>

        )
    }
}
export default EditWarrantyScene
