import React, { Component } from "react";
import { observer } from "mobx-react";
import { Table, Input, Button, Row, Col, Card, Form, Select, message, } from "antd";
import { columnTableProductsWarranty } from "../../../resource";
import {
  PlusCircleOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  ModalAddProductWarranty,
  ModalEditProductWarranty,
  ModalConfirm,
  ModalEditConfirm,
  ModalSuccess,
  ModalLoading,
} from "../../../components/Modal";
import {
  ip,
  GET,
  POST,
  UPLOAD,
  RIZZ_GET_PRODUCTS_WARRANTY,
  RIZZ_GET_PRODUCTS_TYPE,
  RIZZ_INSERT_PRODUCT,
  RIZZ_UPDATE_PRODUCT,
} from "../../../service";
import autobind from "autobind-decorator";
import {
  FORM_ADD_PRODUCT_WARRANTY,
  FORM_EDIT_PRODUCT_WARRANTY,
} from "../../../resource/static";
import { image } from "../../../resource/images";

@observer
class ProductsWarrantyScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openAdd: false,
      openEdit: false,
      productType: "",
      productName: "",
      barcode: "",
      isLoadingData: false,
      fileList: [],
      dataProducts: [],
      optionProductsType: [],
      dataForm: FORM_ADD_PRODUCT_WARRANTY,
      dataEdit: FORM_EDIT_PRODUCT_WARRANTY,
      checked: true,
      info: [],
      img: null,
      productType_form: 1,
      userLoginId: "",
      imgEdit: null,
      statusEdit: true,
      productTypeEdit: "",
      messagesAlert: { title: "", content: "", status: "" },
      openConfirmAdd: false,
      openConfirmEdit: false,
      success: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.getProductsWarranty();
    this.getProductsType();
    this.setState({ userLoginId: localStorage.getItem("userLoginId") });
  }



  getProductsWarranty = async () => {
    const { productName, productType, barcode } = this.state;
    const obj = { productName, productType, barcode };
    // this.setState({ dataProducts: [] });
    try {
      const res = await POST(RIZZ_GET_PRODUCTS_WARRANTY, obj);
      const data = res.result.map((val, i) => {
        return {
          no: i + 1,
          productImage: (
            <img
              width="40%"
              src={
                val.productImage ? `${val.productImage}` : image.noImage
              }
            />
          ),
          productName: val.productName,
          productTypeNameTH: val.productTypeNameTH,
          barcode: val.barcode,
          productEdit: (
            <React.Fragment>
              <EditOutlined
                style={{ fontSize: "22px", color: "#faad14" }}
                onClick={() => this.getDataEdit(val)}
              />
            </React.Fragment>
          ),
          ...val
        };
      });
      this.setState({ dataProducts: data });
    } catch (error) {
      this.setState({ isLoadingData: false });
    }
  };



  getProductsType = async () => {
    try {
      const res = await GET(RIZZ_GET_PRODUCTS_TYPE);
      const dataOption = res.result.map((val, i) => {
        return {
          label: val.productTypeNameTH,
          value: val.productTypeID,
        };
      });
      this.setState({ optionProductsType: dataOption });
    } catch (error) {
      this.setState({ isLoadingData: false });
    }
  };

  handleUpload = (e) => {
    const imgUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({ fileList: e.target.files[0], img: imgUrl });
  };

  handleSelect = (value) => {
    this.setState({ productType_form: value });
  };
  onSelect = (value) => {
    this.setState({ productType: value });
  };
  onChange = (name, value) => {
    if (name === "productName") {
      this.setState({ productName: value });
    } else if (name === "barcode") {
      this.setState({ barcode: value });
    }
  };

  confirmProduct = (form, action) => {
    const obj = {};
    Array.isArray(form) &&
      form.forEach((val) => {
        obj[val.name] = val.value;
      });
    let messageConfirm = 'คุณต้องการเพิ่มสินค้าใช่หรือไม่?'
    if (action === 'EDIT') {
      messageConfirm = 'คุณต้องการแก้ไขสินค้าใช่หรือไม่?'
    }

    if (!obj.barcode) {
      message.error("กรุณากรอกบาร์โค้ด");
    } else if (!obj.productName) {
      message.error("กรุณากรอกชื่อสินค้า");
    } else if (!obj.productCode) {
      message.error("กรุณากรอกรหัสสินค้า");
    } else if (!obj.productTypeID) {
      message.error("กรุณาเลือกประเภทสินค้า");
    } else {
      this.setState({
        messagesAlert: {
          title: "ยืนยัน",
          content: messageConfirm,
          status: "",
        }
      });

      if (action === 'EDIT') {
        this.setState({ openConfirmEdit: true, })
      } else {
        this.setState({ openConfirmAdd: true, })
      }
    }
  };



  handleOnSave = async () => {
    const { dataForm, productType_form, fileList, userLoginId } = this.state;
    const data = new FormData();
    this.setState({ isLoading: true, openConfirmAdd: false, openAdd: false });

    data.append("userLogin", userLoginId);

    dataForm.map((val) => {
      switch (val.type) {
        case "text":
          data.append(val.name, val.value);
          break;
        case "switch":
          data.append(val.name, val.value == true ? 1 : 0);

          break;
        case "select":
          data.append(val.name, productType_form);

          break;
        case "upload":
          data.append(val.name, fileList);

          break;
        default:
          break;
      }
    });

    console.log({ dataForm, productType_form, fileList, userLoginId })
    try {
      const res = await UPLOAD(RIZZ_INSERT_PRODUCT, data);
      if (res.success) {
        dataForm.forEach((val) => {
          if (val.name === "productTypeID") {
            val.value = 1;
          } else if (val.name === "productWarrantyStatus" || val.name === "productClaimStatus") {
            val.value = true;
          } else {
            val.value = "";
          }
        });

        setTimeout(() => {
          this.setState(
            {
              messagesAlert: {
                title: "",
                content: res.message,
                status: "success",
              },
              success: true,
              isLoading: false,
              //   openAdd: false,
              dataForm,
              fileList: [],
              img: null,
            },
            () => this.getProductsWarranty()
          );
        }, 1000);
      }
      //   });
      // this.setState({ dataProducts: data });
    } catch (error) {
      this.setState({
        messagesAlert: { title: "", content: error.message, status: "error" },
        success: true,
        isLoading: false,
        openConfirmAdd: false,
        isLoadingData: false,
        openAdd: false,
      });
    }
  };

  handleOnChange = (name, value, action) => {
    const { dataForm, dataEdit } = this.state
    console.log({ name, value, action,dataForm, dataEdit  })
    if (action === 'EDIT') {
      dataEdit.forEach((val) => {
        if (val.name === name) {
          val.value = value;
        }
      });
      this.setState({ dataEdit });
    } else {
      dataForm.forEach((val) => {
        if (val.name === name) {
          val.value = value;
        }
      });
      this.setState({ dataForm });
    }



  };

  handleUploadEdit = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);

    this.setState({ imgEdit: e.target.files[0] }, () =>
      this.handleOnChangeEdit("productImage", url, 'EDIT')
    );
  };
  handleSwitch = (checked, ref, action) => {
    this.handleOnChange(ref, checked, action);
  };

  handleSelectEdit = (value) => {
    this.handleOnChangeEdit("productTypeID", value, 'EDIT');
  };

  getDataEdit = (data) => {
    const { dataEdit } = this.state;
    console.log({ data })
    Array.isArray(dataEdit) &&
      dataEdit.forEach((val) => {
        switch (val.name) {
          case "productID":
            val.value = data.productID;
            break;
          case "productName":
            val.value = data.productName;
            break;
          case "productCode":
            val.value = data.productCode;
            break;
          case "productTypeID":
            val.value = data.productTypeID;
            break;
          case "barcode":
            val.value = data.barcode;
            break;
          case "colors":
            val.value = data.colors;
            break;
          case "productList":
            val.value = data.productList;
            break;
          case "productWarrantyStatus":
            val.value = data.productWarrantyStatus == 1 ? true : false;
            break;
          case "productClaimStatus":
            val.value = data.productClaimStatus == 1 ? true : false;
            break;
          case "productImage":
            val.value = !!data.productImage
              ? `${data.productImage}`
              : image.noImage;
            break;
          default:
            break;
        }
      });

    this.setState({ openEdit: true });
  };

  handleOnSaveEdit = async () => {
    const { dataEdit, imgEdit, userLoginId } = this.state;

    const data = new FormData();
    this.setState({ isLoading: true, openConfirmEdit: false, openEdit: false });
    data.append("userLogin", userLoginId);
    dataEdit.map((val) => {
      switch (val.type) {
        case "text":
          data.append(val.name, val.value);
          break;
        case "hidden":
          data.append(val.name, val.value);
          break;
        case "switch":
          data.append(val.name, val.value == true ? 1 : 0);
          break;
        case "select":
          data.append(val.name, val.value);
          break;
        case "upload":
          data.append(val.name, imgEdit);
          break;
        default:
          break;
      }
    });

    try {
      const res = await UPLOAD(RIZZ_UPDATE_PRODUCT, data);
      if (res.success) {
        setTimeout(() => {
          this.setState(
            {
              messagesAlert: {
                title: "",
                content: res.message,
                status: "success",
              },
              success: true,
              //   openEdit: false,
              isLoading: false,
            },
            () => this.getProductsWarranty()
          );
        }, 1000);
      }
    } catch (error) {
      this.setState({
        messagesAlert: { title: "", content: error.message, status: "error" },
        success: true,
        isLoading: false,
        openConfirmEdit: false,
        openEdit: false,
        isLoadingData: false,
      });
    }
  };

  render() {
    const {
      openAdd,
      isLoadingData,
      dataProducts,
      optionProductsType,
      productType_form,
      img,
      productName,
      barcode,
      openEdit,
      dataEdit,
      messagesAlert,
      openConfirmEdit,
      openConfirmAdd,
      success,
      isLoading,
      dataForm,
    } = this.state;
    return (
      <div>
        <h2>{"สินค้ารับประกัน"}</h2>
        <Card>
          <Form>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={6}>
                <Form.Item>{"ชื่อสินค้า"}</Form.Item>
                <Input
                  value={productName}
                  onChange={(e) => this.onChange("productName", e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item>{"ประเภทสินค้า"}</Form.Item>
                <Select
                  showSearch
                  placeholder="เลือกประเภทสินค้า"
                  optionFilterProp="children"
                  onChange={this.onSelect}
                  style={{ height: "2em" }}
                  allowClear
                >
                  {optionProductsType.map((props) => {
                    return (
                      <Select.Option value={props.value}>
                        {props.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item>{"บาร์โค้ด"}</Form.Item>
                <Input
                  value={barcode}
                  onChange={(e) => this.onChange("barcode", e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Button
                  onClick={this.getProductsWarranty}
                  className="btn-search"
                >
                  <SearchOutlined />
                  {"ค้นหา"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>

        <div style={{ textAlign: "right", margin: "1em 0em" }}>
          <Button
            type="primary"
            style={{
              borderColor: "#1890ff",
              backgroundColor: "#1890ff",
            }}
            onClick={() => this.setState({ openAdd: true })}
          >
            <PlusCircleOutlined />
            เพิ่มสินค้า
          </Button>
        </div>
        <Table
          size="small"
          rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
          columns={columnTableProductsWarranty}
          loading={isLoadingData}
          dataSource={dataProducts}
        />
        <ModalAddProductWarranty
          title={
            <p
              style={{
                marginBottom: "1px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              {"เพิ่มสินค้ารับประกัน"}
            </p>
          }
          optionProductsType={optionProductsType}
          openAdd={openAdd}
          productType={productType_form}
          img={img}
          handleUpload={this.handleUpload}
          handleSwitch={this.handleSwitch}
          handleChange={this.handleOnChange}
          confirm={() => this.confirmProduct(dataForm, 'ADD')}
          handleSelect={this.handleSelect}
          handleCancel={() => this.setState({ openAdd: false })}
        />
        <ModalEditProductWarranty
          title={
            <p
              style={{
                marginBottom: "1px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              {"แก้ไขสินค้ารับประกัน"}
            </p>
          }
          optionProductsType={optionProductsType}
          openEdit={openEdit}
          dataEdit={dataEdit}
          handleUpload={this.handleUploadEdit}
          handleSwitch={this.handleSwitch}
          handleChange={this.handleOnChange}
          handleOnSave={this.handleOnSaveEdit}
          handleSelect={this.handleSelectEdit}
          confirm={() => this.confirmProduct(dataEdit, 'EDIT')}
          handleCancel={() => this.setState({ openEdit: false })}
        />
        <ModalConfirm
          messagesAlert={messagesAlert}
          visible={openConfirmAdd}
          handleCancel={() =>
            this.setState({
              openConfirmAdd: false,
            })
          }
          handleOnConfirm={this.handleOnSave}
        />
        <ModalEditConfirm
          messagesAlert={messagesAlert}
          visible={openConfirmEdit}
          handleCancel={() =>
            this.setState({
              openConfirmEdit: false,
            })
          }
          handleOnConfirm={this.handleOnSaveEdit}
        />
        <ModalSuccess
          messagesAlert={messagesAlert}
          success={success}
          handleCancel={() =>
            this.setState({
              success: false,
            })
          }
        />
        <ModalLoading isLoading={isLoading} />
      </div>
    );
  }
}
export default ProductsWarrantyScene;
