import { create } from "apisauce";

//export const ip = 'http://localhost:1141/api/v1'
//export const ip = "https://b8aa-2405-9800-b650-bd2f-7484-a499-2457-8f0d.ngrok-free.app/api/v1/";
export const ip = "https://rizz.dplus-frontb.com:2083/api/v1";

export const VERSION = 'V.2.9.0'

const api = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 10 seconds
});

const apiupload = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  timeout: 30000, // 10 seconds
});

const apiapp = create({
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  timeout: 30000, // 10 seconds
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path) =>
  new Promise((resolve, reject) => {
    api
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const CHECK = () => {
  new Promise((resolve, reject) => {
    api
      .post()
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
};

export const GETAPP = (path) =>
  new Promise((resolve, reject) => {
    apiapp
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
//////////// API RIZZ ///////////////////////////
export const LOGIN = "/auth/login";
export const RIZZ_GETCLAIM = "/claim/getListClaimAdmin";
export const RIZZ_GETDETIALBYBARCODE = "/product/getProductByBarcode/";
export const RIZZ_UPDATEPRODUCTBYCLAIMNO = "/claim/updateProductClaim";
export const RIZZ_INSERT_EMS = "/claim/updateTracking";
export const RIZZ_GETHISTORY = "/claim/getClaimHistory";
export const RIZZ_UPDATESTATUS = "/claim/updateApproveClaim";
export const RIZZ_SEND_EMS = "/system/sendMessageTracking";
export const RIZZ_GET_REPORT_WARRANTY = "/report/getReportWarranty";
export const RIZZ_GET_BRANDMOBILE = "/option/getBrandMobile";
export const RIZZ_GET_MODELMOBILE = "/option/getModelByBrand/";
export const RIZZ_REGIS_WARRANTY = "/warranty/insertWarranty";
export const RIZZ_GET_DETAIL_BY_TEL = "/warranty/getWarrantyByTel";
export const RIZZ_GET_OPTION_BREAK = "/option/getBreakdownByProductType/";
export const RIZZ_CLAIM_PRODUCT = "/claim/insertclaim";
export const RIZZ_UPDATE_CLAIM = "/claim/updateClaim";
export const RIZZ_GET_DETIAL_BY_CLAIMNO = "/claim/getClaimDetailByNo/";
export const RIZZ_EXPORT_REPORT = "/export/exportWarranty";
export const RIZZ_EXPORT_CLAIM = "/export/exportClaim";
export const RIZZ_GETREPORT = "/rizz/report/get";
export const RIZZ_DETAILCLAIMBYID = "/rizz/claim/detailclaimById";
export const RIZZ_UPSERTCLAIM = "/rizz/claim/updateclaimById";
// export const RIZZ_GETUSERALL = '/auth/getUserAll'
export const RIZZ_GETUSERID = "/rizz/claim/getUserById";
export const RIZZ_EDITCLAIMBYID = "/rizz/claim/editClaim";
export const RIZZ_GET_PRODUCTS_WARRANTY = "/product/getProducts";
export const RIZZ_GET_PRODUCTS_TYPE = "/product/getProductType";
export const RIZZ_INSERT_PRODUCT = "/product/insertProduct";
export const RIZZ_UPDATE_PRODUCT = "/product/updateProduct";
export const RIZZ_UPDATE_WARRABTY = "/warranty/updateWarranty";
export const RIZZ_GET_ADDRESS_BY_POSTCODE = (postcode)=>`/option/getAddressByPostcode/${postcode}`;
export const RIZZ_GET_OPTION_BANK = "/option/getBankPayment";
export const RIZZ_UPDATE_CLAIM_PAYMENT = "/claim/updateClaimPayment";
export const SEND_MESSAGE_TO_LINE = "/system/sendMsgToLine";
export const FIND_CUSTOMER = "/auth/findCustomer";
export const GET_WARRANTY_BY_WARRANTYNO = (warrantyNo)=>`/warranty/getWarrantyByNo/${warrantyNo}`;
export const GET_OPTION_CHANNEL = `/option/getChannelBuy`;
export const GET_OPTION_BRAND_MOBILE = `/option/getBrandMobile`;
export const APPROVE_WARRANTY_NO = `/warranty/approveWarrantyNo`
