import React from 'react';

export default (title) => (Wrap) => {

    class WrapperComponents extends React.Component {
        constructor(props) {
            super(props)

            this.state = {
                childrenModal: null,

            }
        }

        render() {
            return <div>
                <Wrap
                    {...this.props}
                    {...this.state}
                />
            </div>
        }
    }

    return WrapperComponents;
}