import React from 'react'
import { Icon, Button as Bt } from 'antd';
import { color } from '../resource';
import { BtnStyleConfirm } from '../styles/styled';

export const Button = ({ title, type = 'primary', buttonStyle = defaultButtonMain, onPress = () => { } }) => {
    buttonStyle = {
        ...buttonStyle,
        backgroundColor: type === 'primary' ? color.rizz_primary : color.rizz_main,
        // backgroundColor: type === 'primary' ? color.rizz_primary : color.dplus_primary,
        borderColor: type === 'primary' ? color.rizz_primary : color.rizz_main,
        // borderColor: type === 'primary' ? color.rizz_primary : color.dplus_primary,
        borderRadius: 3,


    }

    return <Bt
        type={'primary'} style={{ ...buttonStyle, }}
        onClick={onPress}
    >
        {title}
    </Bt >
}


export const BtnConfirm = ({ title, onClick = () => alert('test') }) => {
    return <BtnStyleConfirm onClick={onClick}>
        {title}
    </BtnStyleConfirm>
}
export const BtnGoback = ({ title, onClick = () => { } }) => {
    return <a onClick={onClick} style={{ fontSize: '0.8vw' }}><Icon type="arrow-left" /> {title}</a>
}
/*######################  ######################*/
const defaultButtonMain = {
}