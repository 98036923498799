import cryptr from 'crypto-js'
import { KEY_BILL } from '../resource';
import moment from 'moment'
export const encryptParameter = (object) => {
    const encrypt = cryptr.AES.encrypt(JSON.stringify(object), KEY_BILL).toString();
    return encrypt;
}

export const decryptParameter = (encrypt) => {
    return cryptr.AES.decrypt(encrypt, KEY_BILL).toString(cryptr.enc.Utf8);
}
export const append = (data) => {
    //file, name, telephone, province, district, subdistrict, zipcode, other, description, product_id, claim_id, status, line_id, claimdate }
    let fd = new FormData()
    fd.append('userId', data.line_id)
    fd.append('status', parseInt(data.status, 10))
    fd.append('name', data.name)
    fd.append('telephone', data.telephone)
    fd.append('province', data.province)
    fd.append('district', data.district)
    fd.append('amphure', data.subdistrict)
    fd.append('zipcode', data.zipcode)
    fd.append('other', data.other)
    fd.append('description', data.description)
    fd.append('note', data.note)
    fd.append('product_id', data.product_id)
    fd.append('claim_doc', data.claim_doc)
    fd.append('image', data.file)
    fd.append('create_at', data.claimdate)
    fd.append('addressId', data.addressId)
    fd.append('type_sending', data.type_sending)

    return fd
}





export const dateDiff = (endDate) => {
    const now = moment(new Date())
    const end = moment(endDate)
    const days = now.diff(end, 'days')
    return days

}
export const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
export const phonNumberValidate = (phone) => {
    let isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(phone);
    return isphone
}

export const removeDuplicates = (data) => {
    return [...new Map(data.map((x) => [x.value, x])).values()];
  };